import React from 'react';

export default function PhoneNumberInput({ handleChange, handleBlur, value }) {
  return (
    <div className='mobile_phone_input'>
      <div className='mobile_phone_code'>
        <p>+880</p>
      </div>
      <input
        type='text'
        className='mi_custom_textfield'
        id='phone'
        name='phone'
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
    </div>
  );
}
